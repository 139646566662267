import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const UseAuth0Wrapper = (Component: React.ComponentType<any>, extraProps: any | null = null) => {
    return () => {
        const auth0 = useAuth0();
        const props = {...auth0, ...extraProps};

        return <Component {...props} />
    }
}