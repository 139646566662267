/** 
 * This is the main entry point of your React application. 
 * The React application is a React component like any other react components. 
 */
import * as React from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UseAuth0Wrapper } from './shared-components/hoc/authentication/UseAuth0';
import { Auth0ContextInterface, IdToken, User } from '@auth0/auth0-react';
import { RoutesWrapper } from './routes';
import { getAndSetRedirectKey } from '../common/helpers';
import { Topics } from '../common/pubsub-topics';

interface Props extends Auth0ContextInterface<User> {

}

interface State {
  token: IdToken | null;
  userId: string;
  email: string;
}

class AppComponent extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      token: null,
      userId: "",
      email: ""
    }

    context.on("get-token", () => {
      return this.state.token;
    });

    context.on("get-userId", () => {
      return this.state.userId;
    });

    context.on("get-email", () => {
      return this.state.email;
    });

    context.on("get-accessToken", () => {
      return this.props.getAccessTokenSilently();
    });

    subscribe(Topics.SignOut, () => {
      this.props.logout();
    });
  }

  setToken = async () => {
    const token = await this.props.getIdTokenClaims();
    const userId = token["sub"];

    this.setState({
      token,
      userId,
      email: token["email"] ?? ""
    })
  }

  componentDidMount() {
    document.onclick = (e) => {
      if ((e.target as HTMLElement).classList.contains("dropdown-toggle")) {
        const parent = (e.target as HTMLElement).parentElement;

        if (!parent) {
          return;
        }
        const child = parent.querySelector(".dropdown-menu");

        if (!child) {
          return;
        }

        (child as HTMLElement).classList.toggle("show");
      }
    }
  }

  render() {

    if (this.props.isLoading === false && this.props.isAuthenticated === false) {
      const redirectKey = getAndSetRedirectKey();

      // we have no home page, redirect to search page
      let targetUrl = window.location.href;
      if (!window.location.hash || window.location.hash === "#/") {
        targetUrl = `${window.location.origin}/#/home`;
      }

      this.props.loginWithRedirect({ appState: { targetUrl, redirectKey } });
      return null;
    }

    if (this.props.isLoading) {
      return null
    }

    if (!this.state.token) {
      this.setToken();
    }

    return <Router>
      <ToastContainer />
      <Switch>
        {this.state.token && <RoutesWrapper />}
      </Switch>
      <div id="modal-portal"></div>
    </Router>
  }
}



export const App = UseAuth0Wrapper(AppComponent)