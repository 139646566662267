import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import * as React from 'react';

interface Props {
    label: string;
    value: string | number | string[] | number[];
    onChange: (value: string | number | string[] | number[]) => void;
    isEditing: boolean;
    items: { value: string | number, label: string }[];
    multiple?: boolean;
}

export const SelectField: React.FunctionComponent<Props> = (props) => {

    if (props.isEditing === true) {

        return <FormControl variant="filled">
            <InputLabel id="demo-simple-select-filled-label">{props.label}</InputLabel>
            <Select
                multiple={props.multiple}
                value={props.value}
                label={props.label}
                onChange={e => props.onChange(e.target.value as any)}
            >
                {
                    props.items.map(w => <MenuItem key={`menu-item-${w.value}`} value={w.value}>{w.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    }

    const item = props.items.find(w => w.value == props.value);

    return <React.Fragment>
        <p className="panel-key">{props.label}</p>
        <div className="panel-value">{item ? item.label : ""}</div>
    </React.Fragment>
}