import * as React from 'react';
import { LoadingButton, SpinnerSize } from '../../shared-components/buttons/LoadingButton';

interface Props {
    title: string;
    isLoading: boolean;
    onNewClick: () => void;
    onUploadClick: () => void;
    onClearClick: () => void;
}

export const SectionHeader: React.FunctionComponent<Props> = (props) => {
    return <div className="section-header">
        <h3 className="section-title">{props.title}</h3>
        <div className="right">
            <LoadingButton isLoading={props.isLoading} className="primary-button new-freight-btn" onClick={props.onNewClick}>
                <i className="far fa-plus"></i>&emsp;New
            </LoadingButton>
            <div className="upload-section">
                <LoadingButton isLoading={props.isLoading} className="primary-button new-freight-btn" onClick={props.onUploadClick}>
                    <i className="fas fa-file-upload"></i>&emsp;Upload {props.title}
                </LoadingButton>
            </div>
            <LoadingButton spinnerSize={SpinnerSize.Small} isLoading={props.isLoading} className="section-button secondary-button clear-button" onClick={props.onClearClick}>
                <span>Clear Table</span>
            </LoadingButton>
        </div>
    </div>
}