import * as React from "react";
import { useLocation } from "react-router-dom";
import * as H from 'history';

type OnRouteChangeCallback = (location: H.Location<any>) => void

export const RouteChange = (onRouteChange: OnRouteChangeCallback) => {
    let location = useLocation();

    React.useEffect(
        () => {
            onRouteChange(location)
        },
        [location]
    )
}