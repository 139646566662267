import * as React from 'react';

interface Props {

}

export class DriverProfile extends React.PureComponent<Props> {

    render() {
        return <div>
            Driver Profile
        </div>
    }
}