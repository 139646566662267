import * as React from 'react';
import { Route } from 'react-router-dom';
import { Home } from '../pages/home/Home';
import { Freight } from '../pages/freight/Freight';
import { UseAuth0Wrapper } from '../shared-components/hoc/authentication/UseAuth0';
import { Landing } from '../pages/landing/Landing';
import { AuthorizedLayout } from '../layouts/AuthorizedLayout';
import { Drivers } from '../pages/drivers/Drivers';
import { DriverProfile } from '../pages/drivers/sub-pages/driver-profile/DriverProfile';
import { FreightDetail } from '../pages/freight/sub-pages/freight-detail/FreightDetail';

export interface ISecureRoutePartial {
    visible: boolean;
    name: string;
    path: string;
    requiresAuthentication: boolean;
    layout?: React.ComponentType<any>;
}

export interface ISecureRoute extends ISecureRoutePartial {
    component: (props: ISecureRoute) => React.FunctionComponent<any>,
    allowedRoles: string[];
};

export enum Roles {
}

const ProtectedRoute = (routeProps: ISecureRoute) => {
    const Component = routeProps.component(routeProps);
    return <Component key={routeProps.path} />
}

export const getAvailableRoutes = (roles: string[]) => {

    return routes.filter(w => w.allowedRoles.length === 0 || w.allowedRoles.some(x => roles.includes(x))).map(w => ({
        name: w.name,
        path: w.path,
        visible: w.visible
    }) as ISecureRoutePartial)
}

export const routes: ISecureRoute[] = [
    {
        path: "/",
        visible: true,
        name: "Landing",
        requiresAuthentication: false,
        component: (props) => () => <Route exact path={props.path} render={p => render(Landing, props, p)} />,
        allowedRoles: []
    },
    {
        path: "/home",
        visible: true,
        name: "Home",
        requiresAuthentication: true,
        component: (props) => () => <Route exact path={props.path} render={p => render(Home, props, p)} />,
        allowedRoles: [],
        layout: AuthorizedLayout
    },
    {
        path: "/freight",
        visible: true,
        name: "Freight",
        requiresAuthentication: true,
        component: (props) => () => <Route exact path={props.path} render={p => render(Freight, props, p)} />,
        allowedRoles: [],
        layout: AuthorizedLayout
    },
    {
        path: "/freight/:id",
        visible: true,
        name: "Freight Detail",
        requiresAuthentication: true,
        component: (props) => () => <Route exact path={props.path} render={p => render(FreightDetail, props, p)} />,
        allowedRoles: [],
        layout: AuthorizedLayout
    },
    {
        path: "/drivers",
        visible: true,
        name: "Drivers",
        requiresAuthentication: true,
        component: (props) => () => <Route exact path={props.path} render={p => render(Drivers, props, p)} />,
        allowedRoles: [],
        layout: AuthorizedLayout
    },
    {
        path: "/drivers/:id",
        visible: true,
        name: "Driver Profile",
        requiresAuthentication: true,
        component: (props) => () => <Route exact path={props.path} render={p => render(DriverProfile, props, p)} />,
        allowedRoles: [],
        layout: AuthorizedLayout
    }
];

const render = (component: React.ComponentType<any>, routeProps: ISecureRoute, props: any) => {
    const Component = UseAuth0Wrapper(component, props);
    const Layout = routeProps.layout;
    return Layout != null ? <Layout><Component /></Layout> : <Component />

}

export const RoutesWrapper: React.FunctionComponent = () => {
    return <React.Fragment>{routes.map(w => ProtectedRoute(w))}</React.Fragment>
}