/// <reference path="../../../../common-types.d.ts"/>
import * as React from 'react';
import { Loader } from "@googlemaps/js-api-loader"

interface Props {
    className?: string;
    freight?: IFreight | null;
}

export class GoogleMap extends React.PureComponent<Props> {

    // https://developers.google.com/maps/documentation/javascript/reference/directions
    map: google.maps.Map | null = null;

    async componentDidMount() {
        const loader = new Loader({
            apiKey: "AIzaSyCt1CUpPJwihtNuSMo7e_80UaHjJmkJZ3k",
            version: "weekly",
        });

        await loader.load();

        this.map = new google.maps.Map(document.getElementById("google-map") as HTMLElement, {
            center: { lat: 44.798519, lng: -93.526810 },
            zoom: 8,

        });
        if (this.props.freight?.route != null && this.props.freight.route.routeLegs.length > 0) {

            const legs = this.props.freight.route.routeLegs.filter(w => w.routeLegTypeId == 1);
            const startLeg = legs[0];
            const endLeg = legs[this.props.freight.route.routeLegs.length - 1];
            const viaWaypoints = legs.slice(1, legs.length - 1);

            this.map.fitBounds(new google.maps.LatLngBounds({
                lat: this.props.freight.dropoffPlace.latitude,
                lng: this.props.freight.dropoffPlace.longitude
            }, {
                lat: this.props.freight.pickupPlace.latitude,
                lng: this.props.freight.pickupPlace.longitude
            }));

            const line = new google.maps.Polyline({
                path: legs.map(w => new google.maps.LatLng({
                    lat: w.startLatitude,
                    lng: w.startLongitude
                })),
                strokeColor: '#FF0000',
                strokeOpacity: 0.5,
                strokeWeight: 4,
                map: this.map
            });

            for(let point of viaWaypoints) {
                const marker = new google.maps.Marker({
                    icon: {
                      path: google.maps.SymbolPath.CIRCLE,
                      scale: 3
                    },
                    position: new google.maps.LatLng({
                        lat: point.startLatitude,
                        lng: point.startLongitude
                    }),
                    map: this.map
                  });
            }

            // const directionsService = new google.maps.DirectionsService();

            // const response = await directionsService.route({
            //     origin: this.props.freight.pickupPlace.formattedAddress,
            //     destination: this.props.freight.dropoffPlace.formattedAddress,
            //     travelMode: google.maps.TravelMode.DRIVING
            // });

            // new google.maps.DirectionsRenderer({
            //     map: this.map,

            //     directions: {
            //         // geocoded_waypoints: response.geocoded_waypoints,
            //         routes: response.routes,
            //         request: {
            //             travelMode: "DRIVING"
            //         }
            //     } as any
            // });

            new google.maps.DirectionsRenderer({
                map: this.map,
                directions: {
                    routes: [
                        {
                            bounds: new google.maps.LatLngBounds({
                                lat: this.props.freight.route.startLatitude,
                                lng: this.props.freight.route.startLongitude
                            }, {
                                lat: this.props.freight.route.endLatitude,
                                lng: this.props.freight.route.endLongitude
                            }),
                            legs: [
                                {
                                    end_address: this.props.freight.dropoffPlace.formattedAddress,
                                    end_location: new google.maps.LatLng({
                                        lat: endLeg.endLatitude,
                                        lng: endLeg.endLongitude
                                    }),
                                    start_address: this.props.freight.pickupPlace.formattedAddress,
                                    start_location: new google.maps.LatLng({
                                        lat: startLeg.startLatitude,
                                        lng: startLeg.startLongitude
                                    }),
                                    steps: [startLeg, endLeg].map(x => {
                                        return {
                                            end_location: new google.maps.LatLng({
                                                lat: x.endLatitude,
                                                lng: x.endLongitude
                                            }),
                                            instructions: "",
                                            path: [
                                                new google.maps.LatLng({
                                                    lat: x.startLatitude,
                                                    lng: x.startLongitude
                                                }),
                                                new google.maps.LatLng({
                                                    lat: x.endLatitude,
                                                    lng: x.endLongitude
                                                })
                                            ],
                                            start_location: new google.maps.LatLng({
                                                lat: x.startLatitude,
                                                lng: x.startLongitude
                                            }),
                                            travel_mode: google.maps.TravelMode.DRIVING
                                        }
                                    }),
                                    via_waypoints: viaWaypoints.map(x => new google.maps.LatLng({
                                        lat: x.startLatitude,
                                        lng: x.startLongitude
                                    }))
                                }
                            ],
                            copyrights: "Map data ©2021",
                            overview_path: [],
                            overview_polyline: "",
                            waypoint_order: [],
                            warnings: [],
                        }
                    ],
                    request: {
                        travelMode: "DRIVING"
                    }
                } as any
            })
        }
    }

    render() {
        return <div style={{ width: "100%", height: "400px" }} className={this.props.className} id="google-map"></div>
    }
}