import * as React from 'react';
import './LayoutHeader.scss';
import { Topics } from '../../../../common/pubsub-topics';
import * as schedulingRepository from '../../../../repository/scheduling-repository';
import { Popover } from '@material-ui/core';

interface Props {
}

export const LayoutHeader: React.FunctionComponent<Props> = (props) => {

    const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);

    const onUpdateRoutingClick = async () => {
        publish(Topics.UpdateRoutingStarted);
        try {
            const freight = await schedulingRepository.updateSchedule();
            publish(Topics.UpdateRoutingEnded, freight);
        } catch {
            publish(Topics.UpdateRoutingEnded, []);
        }
    }

    const onClose = () => {
        setIsPopoverVisible(false);
    }

    const name = context.get<string>("get-email");

    return <div className="layout-header">
        <div className="header-left">
            <div className="search">
                <img src="static/images/search.svg" />
                <input
                    v-model="search_text"
                    placeholder="Search drivers, customers, or help"
                />
            </div>
        </div>
        <div className="header-right">
            <div className="header-right-item">
                <p className="update" onClick={onUpdateRoutingClick}>Update Routing</p>
            </div>
            <div className="header-right-item"><i className="fas fa-bell"></i></div>
            <div className="header-right-item"><i className="fas fa-question-circle"></i></div>
            <div className="header-right-item" id="popover-anchor" onClick={() => setIsPopoverVisible(true)}><i className="fas fa-user"></i></div>
            <Popover
                id="user-profile-popover"
                open={isPopoverVisible}
                anchorEl={document.getElementById("popover-anchor")!}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="user-profile-popover">
                    <div>
                        <img src="static/images/up-arrow.svg" alt="" />
                    </div>
                    <div className="user-profile-information">
                        <h3 className="user-profile-name">
                            {name ?? 'N/A'}
                        </h3>
                        <p className="user-profile-role">{'Administrator' ?? 'N/A'}</p>
                    </div>
                    <hr />
                    <div className="user-profile-actions">
                        <a>Profile</a>
                        <a onClick={() => publish(Topics.SignOut)}>Sign out</a>
                    </div>
                </div>
            </Popover>
        </div>
    </div>
}