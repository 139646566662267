import moment = require("moment");

const redirectStorageKey = "route_tree_redirect_key";

export const isUrlValid = (url: string) => {

  try {
    const value = new URL(url);

    return value.protocol === "http:" || value.protocol === "https:";
  } catch (_) {
    return false;
  }
}

export const getAndSetRedirectKey = () => {
  const key = makeid(30);
  localStorage.setItem(redirectStorageKey, key);
  return key;
}

export const isRedirectKeyValid = (key: string) => {
  const localStorageKey = localStorage.getItem(redirectStorageKey);
  localStorage.removeItem(redirectStorageKey);
  return localStorageKey == key;
}

// https://stackoverflow.com/a/1349426/3329760
export const makeid = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatEta = (value: number) => {

  const hours = Math.floor(value / 60);
  const minutes = Math.round(value % 60);
  let eta = "";

  if (hours > 0) {
    eta = `${hours} h`;
  }

  if (minutes > 0) {
    eta += ` ${minutes} m`;
  } 

  if (hours == 0 && minutes == 0) {
    return "< 1 min";
  }

  return eta;
}

export const getRouteType = (value: number) => {
  switch (value) {
    case 1:
      return "Drive";
    case 2:
      return "Duty";
    case 3:
      return "Rest";
    default:
      return "N/A";
  }
}