import * as React from 'react';
import './AuthorizedLayout.scss';
import { LayoutHeader } from './sub-components/header/LayoutHeader';
import { RouteChange } from '../shared-components/router/RouterChange';

interface Props {

}

export const AuthorizedLayout: React.FunctionComponent<Props> = (props) => {

    const [activeHash, setActiveHash] = React.useState(location.hash);

    RouteChange(() => {
        console.log(location.hash)
        setActiveHash(location.hash)
    });

    const links: { hash: string, titie: string, icon: string }[] = [
        { hash: "#/home", titie: "Home", icon: "fad fa-home-lg-alt" },
        { hash: "#/drivers", titie: "Drivers", icon: "fad fa-steering-wheel" },
        { hash: "#/freight", titie: "Freight", icon: "fad fa-truck-container" },
        { hash: "#/dashboard", titie: "Reports", icon: "fad fa-file-chart-line" },
        { hash: "#/settings", titie: "Settings", icon: "fas fa-cog" }
    ]

    return <div className="layout-container">
        <div className="rt-navbar">
            <div className="logo-box">
                <img alt="Route Tree logo" src="static/images/logo-wide-dark.svg" className="logo" />
            </div>
            <ul className="nav-list">
                {
                    links.map(w => <a href={w.hash} className={activeHash === w.hash ? "router-link-active" : ""}>
                        <i className={w.icon}></i><p>{w.titie}</p>
                    </a>)
                }
            </ul>
        </div>
        <div className="layout-content">
            <LayoutHeader />
            <div className="layout-render-content">
                {props.children}
            </div>
        </div>
    </div>
}