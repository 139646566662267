/// <reference path="../../../repository/freight-repository.d.ts"/>
import { DataGrid, GridColumns, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import * as React from 'react';
import { currencyFormatter, formatEta } from '../../../common/helpers';
import { Topics } from '../../../common/pubsub-topics';
import { fileDialog } from '../../../external-modules/FileDialog';
import * as freightRepository from '../../../repository/freight-repository';
import * as healthRepository from '../../../repository/health-repository';
import { ConfirmModal } from '../../shared-components/modals/ConfirmModal';
import { ModalPortal } from '../../shared-components/portal/ModalPortal';
import { SectionHeader } from '../../shared-components/section-header/SectionHeader';
import './Freight.scss';

interface Props {

}

interface State {
    freight: IFreight[];
    grid: {
        pageSize: number
    },
    isConfirmClearFreightModalVisible: boolean;
    isLoading: boolean;
}

export class Freight extends React.PureComponent<Props, State> {

    state: State = {
        freight: [],
        grid: {
            pageSize: 5
        },
        isConfirmClearFreightModalVisible: false,
        isLoading: true
    }
    
    updateRoutingStartedUnsubscribe: Function | null = null;
    updateRoutingEndedUnsubscribe: Function | null = null;

    getValue = (data: any, getter: (freight: IFreight) => any) => {
        return getter(data.row);
    }

    getEta = (freight: IFreight) => {

        if (freight.route?.routeLegs && freight.route.routeLegs.length > 0) {
            return freight.route.routeLegs.map(x => x.estimatedTimeToComplete).reduce((a, b) => a + b, 0);
        }

        return -1;
    }

    getDriverFullName = (freight: IFreight) => {

        if (freight.assignedDriver != null) {
            return `${freight.assignedDriver.firstName} ${freight.assignedDriver.lastName}`;
        }

        return "N/A";
    }

    getRpm = (freight: IFreight) => {
        return freight.route?.ratePerMile ?? -1;
    }

    getMiles = (freight: IFreight) => {

        if (freight.route?.routeLegs && freight.route.routeLegs.length > 0) {
            let routeDistance = freight.route.distance;
            const deadhead = freight.route.routeLegs.find(x => x.name.toLowerCase().includes("deadhead"));

            if (deadhead) {
                routeDistance += deadhead.distance;
            }

            return routeDistance;
        }

        return -1
    }

    formatMiles = (data: GridValueFormatterParams) => {
        if (data.value === -1) {
            return "N/A"
        }

        return Number(data.value).toFixed(2);
    }

    formatRpm = (data: GridValueFormatterParams) => {
        if (data.value === -1) {
            return "N/A"
        }

        return `~${currencyFormatter.format(data.value as number)}`;
    }

    formatRevenue = (data: GridValueFormatterParams) => {
        if (data.value === -1) {
            return "N/A"
        }

        return currencyFormatter.format(data.value as number);
    }

    onDetailClick = (freightId:number) => {
        window.location.href = `#/freight/${freightId}`;
    }

    onRenderActions = (e:GridRenderCellParams) => {
        return <React.Fragment>
            <button className="primary-button" onClick={() => this.onDetailClick(e.row.freightId)}>Detail</button>
        </React.Fragment>
    }

    columns: GridColumns = [
        { field: "customerName", headerName: "Customer", valueGetter: w => this.getValue(w, x => x.customer.customerName) },
        { field: "loadNumber", headerName: "Load #", valueGetter: w => this.getValue(w, x => x.freightId) },
        { field: "revenue", headerName: "Revenue", valueGetter: w => this.getValue(w, x => x.revenue), valueFormatter: this.formatRevenue },
        { field: "miles", headerName: "Miles", valueGetter: w => this.getMiles(w.row as any), valueFormatter: this.formatMiles },
        { field: "rpm", headerName: "RPM", valueGetter: w => this.getRpm(w.row as any), valueFormatter: this.formatRpm },
        { field: "pickup", headerName: "Pickup", valueGetter: w => this.getValue(w, x => x.pickupPlace.formattedAddress) },
        { field: "dropoff", headerName: "Dropoff", valueGetter: w => this.getValue(w, x => x.dropoffPlace.formattedAddress) },
        { field: "driver", headerName: "Driver", valueGetter: w => this.getDriverFullName(w.row as any) },
        { field: "tripTime", headerName: "Trip Time", valueGetter: w => this.getEta(w.row as any), valueFormatter: w => formatEta(w.value as number) },
        { field: "status", headerName: "Status", valueGetter: w => this.getValue(w, x => x.freightStatus.freightStatusName) },
        { field: "", headerName: "Actions", renderCell: this.onRenderActions }
    ];

    async componentDidMount() {
        const freight = await freightRepository.getFreight();

        const response = await healthRepository.getIsHealthy();
        
        console.log(response);

        this.setState({ freight, isLoading: false });

        this.updateRoutingStartedUnsubscribe = subscribe(Topics.UpdateRoutingStarted, () => this.setState({ isLoading: true })).remove;
        this.updateRoutingEndedUnsubscribe = subscribe(Topics.UpdateRoutingEnded, this.onUpdateRouting).remove;
    }

    componentWillUnmount() {
        if (this.updateRoutingStartedUnsubscribe) {
            this.updateRoutingStartedUnsubscribe();
        }

        if (this.updateRoutingEndedUnsubscribe) {
            this.updateRoutingEndedUnsubscribe();
        }
    }

    onUpdateRouting = (freight: IFreight[]) => {
        this.setState({ freight, isLoading: false });
    }

    onClearClick = () => {
        this.setState({ isConfirmClearFreightModalVisible: true });
    }

    onNewClick = () => {

    }

    onUploadClick = async () => {
        const files = await fileDialog({ accept: ".csv" });

        if (files.length === 0) {
            return;
        }
        this.setState({ isLoading: true })

        await freightRepository.uploadFreight(files[0]);
        const freight = await freightRepository.getFreight();
        this.setState({ freight, isLoading: false });
    }

    handleClearDrivers = async () => {
        this.setState({ isLoading: true })
        await freightRepository.deleteFreight();
        const freight = await freightRepository.getFreight();

        this.setState({ freight, isConfirmClearFreightModalVisible: false, isLoading: false })
    }

    render() {
        let rowCount = Math.min(this.state.grid.pageSize, this.state.freight.length) + 1;

        if (this.state.freight.length == 0) {
            rowCount++;
        }

        return <div className="freight-page">
            <SectionHeader
                isLoading={this.state.isLoading}
                title="Freight"
                onClearClick={this.onClearClick}
                onNewClick={this.onNewClick}
                onUploadClick={this.onUploadClick}
            />
            <div style={{ display: "flex", height: (rowCount * 52) + 56 }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        getRowId={w => w.freightId}
                        rows={this.state.freight}
                        columns={this.columns}
                        onPageSizeChange={pageSize => this.setState({ grid: { pageSize } })}
                        pageSize={this.state.grid.pageSize}
                        rowsPerPageOptions={[5, 10, 15, 20, 50]}
                        pagination={true}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                        hideFooterSelectedRowCount={false}
                        disableColumnMenu={true}
                    />
                </div>
            </div>
            <ModalPortal>
                {this.state.isConfirmClearFreightModalVisible === true && <ConfirmModal
                    onClose={() => this.setState({ isConfirmClearFreightModalVisible: false })}
                    onConfirm={this.handleClearDrivers}
                    message="Are you sure you want to clear freight?"
                />}
            </ModalPortal>
        </div>
    }
}