import * as React from 'react';
import { createPortal } from 'react-dom';

interface Props {
    className?: string;
    onClose?: () => void;
    size?: ModalSize;
}

export enum ModalSize {
    Large = "modal-lg",
    Default = ""
}

export const ModalPortal: React.FunctionComponent<Props> = (props) => {

    if (!props.children) {
        return null
    }

    const mount = document.getElementById("modal-portal")!;

    return createPortal(<div onClick={() => props.onClose ? props.onClose() : void (0)} className="dialog-overlay" style={{ display: "block" }} tabIndex={-1}>
        <div className={`dialog-content ${props.className}`}>
            {props.children}
        </div>
    </div>, mount);
}