/// <reference path="../../../repository/freight-repository.d.ts"/>
import { DataGrid, GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import * as React from 'react';
import { SectionHeader } from '../../shared-components/section-header/SectionHeader';
import * as driversRepository from '../../../repository/drivers-repository';
import { ModalPortal } from '../../shared-components/portal/ModalPortal';
import { ConfirmModal } from '../../shared-components/modals/ConfirmModal';
import './Drivers.scss';
import { fileDialog } from '../../../external-modules/FileDialog';

interface Props {

}

interface State {
    drivers: IDriver[];
    grid: {
        pageSize: number
    },
    isConfirmClearDriversModalVisible: boolean;
    isLoading: boolean;
}

export class Drivers extends React.PureComponent<Props, State> {

    state: State = {
        drivers: [],
        grid: {
            pageSize: 5
        }, 
        isConfirmClearDriversModalVisible: false,
        isLoading: true
    }

    getValue = (data: any, getter: (driver: IDriver) => any) => {
        return getter(data.row);
    }

    getEta = (freight: IFreight) => {

        if (freight.route?.routeLegs && freight.route.routeLegs.length > 0) {
            return freight.route.routeLegs.map(x => x.estimatedTimeToComplete).reduce((a, b) => a + b, 0);
        }

        return -1;
    }

    getDriverFullName = (driver: IDriver) => {
        return `${driver.firstName} ${driver.lastName}`;
    }

    getManagerFullName = (driver: IDriver) => {
        return `${driver.manager.firstName} ${driver.manager.lastName}`;
    }

    columns: GridColumns = [
        { field: "name", flex: 1 / 6, headerName: "Name", valueGetter: w => this.getDriverFullName(w.row as any) },
        { field: "hometown", flex: 1 / 6, headerName: "Hometown", valueGetter: w => this.getValue(w, x => x.hometownPlace.formattedAddress) },
        { field: "loadNumber", flex: 1 / 6, headerName: "Load #", valueGetter: w => "" },
        { field: "status", flex: 1 / 6, headerName: "Status", valueGetter: w => this.getValue(w, x => x.driverStatus.driverStatusName) },
        { field: "manager", flex: 1 / 6, headerName: "Manager", valueGetter: w => this.getManagerFullName(w.row as any) },
        { field: "score", flex: 1 / 6, headerName: "Score", valueGetter: w => "" }
    ];

    async componentDidMount() {
        const drivers = await driversRepository.getDrivers();

        this.setState({ drivers, isLoading: false })
    }

    onClearClick = () => {
        this.setState({ isConfirmClearDriversModalVisible: true });
    }

    onNewClick = () => {

    }

    onUploadClick = async () => {

        const files = await fileDialog({ accept: ".csv" });

        if (files.length === 0) {
            return;
        }

        this.setState({ isLoading: true })

        try {
            await driversRepository.uploadDrivers(files[0]);
            const drivers = await driversRepository.getDrivers();
            this.setState({ drivers, isLoading: false });
        } catch  {
            // error
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleClearDrivers = async () => {
        this.setState({ isLoading: true })
        await driversRepository.deleteDrivers();
        const drivers = await driversRepository.getDrivers();

        this.setState({ drivers, isConfirmClearDriversModalVisible: false, isLoading: false })
    }

    render() {
        let rowCount = Math.min(this.state.grid.pageSize, this.state.drivers.length) + 1;

        if (this.state.drivers.length == 0) {
            rowCount++;
        }

        return <div className="freight-page">
            <SectionHeader
                isLoading={this.state.isLoading}
                title="Drivers"
                onClearClick={this.onClearClick}
                onNewClick={this.onNewClick}
                onUploadClick={this.onUploadClick}
            />
            <div style={{ display: "flex", height: (rowCount * 52) + 56 }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        getRowId={w => w.driverId}
                        rows={this.state.drivers}
                        columns={this.columns}
                        onPageSizeChange={pageSize => this.setState({ grid: { pageSize } })}
                        pageSize={this.state.grid.pageSize}
                        rowsPerPageOptions={[5, 10, 15, 20, 50]}
                        pagination={true}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                        hideFooterSelectedRowCount={false}
                        disableColumnMenu={true}
                    />
                </div>
            </div>
            <ModalPortal>
                { this.state.isConfirmClearDriversModalVisible === true && <ConfirmModal
                    onClose={() => this.setState({ isConfirmClearDriversModalVisible: false })}
                    onConfirm={this.handleClearDrivers}
                    message="Are you sure you want to clear drivers?"
                />}
            </ModalPortal>
        </div>
    }
}