import * as React from 'react';

interface Props extends React.ButtonHTMLAttributes<any> {
    isLoading: boolean;
    spinnerSize?: SpinnerSize;
}

export enum SpinnerSize {
    Small,
    Default,
    Large
}

export const LoadingButton: React.FunctionComponent<Props> = (props) => {


    const spanCss: React.CSSProperties = {

    }

    const buttonCss: React.CSSProperties = {
        position: 'relative'
    }

    const iconCss: React.CSSProperties = {
        position: "absolute",
        left: "calc(50% - 13px)",
        top: "calc(50% - 11px)",
        fontSize: 24
    }


    if (props.isLoading) {
        spanCss.color = "transparent";
        spanCss.width = "0px";
        spanCss.opacity = 0;
        buttonCss.pointerEvents = "none";
    }

    if (props.spinnerSize === SpinnerSize.Large) {
        iconCss.fontSize = 30;
    }

    if (props.spinnerSize === SpinnerSize.Small) {
        iconCss.fontSize = 18;
        iconCss.left = "calc(50% - 9px)";
        iconCss.top = "calc(50% - 9px)";
    }

    return <button {...props} style={buttonCss}><span style={spanCss}>{props.children}</span>{props.isLoading && <i style={iconCss} className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>}</button>
}