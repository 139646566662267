/// <reference path="../common-types.d.ts"/>
/// <reference path="./drivers-repository.d.ts"/>
import { createAuthorizedHeaders } from "./base/base-repository";

export const getDrivers = async (): Promise<IDriver[]> => {
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/drivers`, {
        headers,
        method: "GET"
    });

    if (response.ok === false) {
        return [];
    }

    return await response.json();
}

export const updateDriver = async (driver:IDriver): Promise<IDriver | null> => {
    const headers = await createAuthorizedHeaders({ "Content-Type": "application/json" });
    const response = await fetch(`${appSettings.apiEndpoint}/drivers`, {
        headers,
        method: "PUT",
        body: JSON.stringify(driver)
    });

    if (response.ok === false) {
        return null;
    }

    return await response.json();
}

export const deleteDrivers = async (): Promise<boolean> => {
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/drivers`, {
        headers,
        method: "DELETE"
    });

    return response.ok;
}

export const uploadDrivers = async (file: File): Promise<IDriver[]> => {

    const form = new FormData();

    form.append('files', file, file.name);
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/drivers/upload`, {
        headers,
        method: "POST",
        body: form
    });

    if (response.ok === false) {
        return [];
    }

    return await response.json();
}