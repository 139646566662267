import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

interface Props {
    onClose: () => void;
    message: string
    onConfirm: () => void;
}

export const ConfirmModal: React.FunctionComponent<Props> = (props) => {
    return <Dialog onClose={props.onClose} open={true}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
            <p>{props.message}</p>
        </DialogContent>
        <DialogActions>
        <Button onClick={props.onClose} color="secondary">
                Cancel
            </Button>
            <Button onClick={props.onConfirm} color="primary">
                Ok
            </Button>
        </DialogActions>
    </Dialog>
}