import * as React from 'react';
import './Home.scss';
import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { fileDialog } from '../../../external-modules/FileDialog';
import { DataGrid, GridColumns, GridRowData, GridValueGetterParams } from '@mui/x-data-grid';
import * as driversRepository from '../../../repository/drivers-repository';
import { GoogleMap } from '../../shared-components/google/map/GoogleMap';

interface Props extends Auth0ContextInterface<User> {

}

interface State {
    drivers: GridRowData[];
}

export class Home extends React.PureComponent<Props, State> {

    state: State = {
        drivers: []
    }

    async componentDidMount() {
        const drivers = await driversRepository.getDrivers();

        this.setState({
            drivers
        })
    }

    onSignInClick = () => {
        this.props.loginWithRedirect();
    }

    onUploadFreightClick = async () => {
        const files = await fileDialog({
            multiple: false,
            accept: ".csv"
        });

        if (files.length === 0) {
            return;
        }
    }

    getFullName = (params: GridValueGetterParams) => {
        return `${params.getValue(params.id, 'firstName') || ''} ${params.getValue(params.id, 'lastName') || ''
            }`;
    }

    columns: GridColumns = [
        { field: "fullName", headerName: "Name", flex: 1, resizable: true, valueGetter: this.getFullName },
        { field: "hometownPlace", headerName: "Hometown", flex: 1, resizable: true, renderCell: w => w.row.hometownPlace.formattedAddress }
    ];

    render() {
        return <div className="page-background" id="home-page">
            <div className="row">
                <div className="col-sm-12">
                    <div className="section-header">
                        <h3 className="section-title">Map</h3>
                    </div>
                    <GoogleMap 
                    
                    />
                    
                    {/* <HomeMap
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCt1CUpPJwihtNuSMo7e_80UaHjJmkJZ3k&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    /> */}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <div className="section-header">
                        <h3 className="section-title">Freight</h3>
                    </div>
                    <div className="text-center clickable" onClick={this.onUploadFreightClick}>
                        <img src="static/images/upload.svg" className="upload-icon" />
                        <div>
                            Click to select files <br /><br />
                            <strong>- OR -</strong> <br /><br />
                            Drag and Drop Files
                        </div>
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="section-header">
                        <h3 className="section-title">Drivers</h3>
                    </div>
                    <DataGrid
                        getRowId={w => w.driverId}
                        rows={this.state.drivers}
                        columns={this.columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection={false}
                        disableSelectionOnClick
                    />
                </div>
            </div>
        </div>
    }
}