/// <reference path="../common-types.d.ts"/>
/// <reference path="./freight-repository.d.ts"/>
import { createAuthorizedHeaders } from "./base/base-repository";

export const getFreight = async (): Promise<IFreight[]> => {
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/freight`, {
        headers,
        method: "GET"
    });

    if (response.ok === false) {
        return [];
    }

    return await response.json();
}

export const getFreightById = async (id: number | string): Promise<IFreight | null> => {
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/freight/${id}`, {
        headers,
        method: "GET"
    });

    if (response.ok === false) {
        return null;
    }

    return await response.json();
}

export const deleteFreight = async (): Promise<boolean> => {
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/freight`, {
        headers,
        method: "DELETE"
    });

    return response.ok;
}

export const uploadFreight = async (file: File): Promise<IFreight[]> => {

    const form = new FormData();

    form.append('files', file, file.name);
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/freight/upload`, {
        headers,
        method: "POST",
        body: form
    });

    if (response.ok === false) {
        return [];
    }

    return await response.json();
}