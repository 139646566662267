import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './app/App';
import 'whatwg-fetch';// polyfill window.fetch
import './index.scss';
import { Auth0Provider } from '@auth0/auth0-react';
import { isRedirectKeyValid } from './common/helpers';

type DataStoreFunction = () => void;
type PubSubFunction = (data?: any) => void;
const channels: { [key: string]: DataStoreFunction } = {};
const topics: { [key: string]: PubSubFunction[] } = {};

(window as any).publish = (topic: string, info?: any) => {

  if (topics.hasOwnProperty(topic) === false) {
    return;
  }

  for (var item of topics[topic]) {
    if (item != null) {
      item(info);
    }
  }
}

(window as any).subscribe = (topic: string, listener: PubSubFunction) => {
  if (topics.hasOwnProperty(topic) === false) {
    topics[topic] = [];
  }

  var index = topics[topic].push(listener) - 1;

  return {
    remove: () => {
      delete topics[topic][index];
    }
  }
}

(window as any).context = {
  get: function <T>(topic: string) {

    if (channels.hasOwnProperty(topic) === false) {
      return;
    }

    return (channels[topic]() as any) as T
  },
  on: function (topic: string, listener: DataStoreFunction) {
    if (channels.hasOwnProperty(topic) === false) {
      channels[topic] = listener;
    }

    return {
      remove: function () {
        delete channels[topic];
      }
    }
  }
}

ReactDOM.render(
  <Auth0Provider
    domain="route-tree.us.auth0.com"
    clientId="vh1C2rFtv1tu81CA62lh1jw6vIkaxV2j"
    audience="http://localhost:3000/api"
    redirectUri={window.location.origin}
    onRedirectCallback={appState => {
      if (appState.targetUrl && isRedirectKeyValid(appState.redirectKey)) {
        window.location.replace(appState.targetUrl);
      }
    }}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root') as HTMLElement
);