import * as React from 'react';
import './Landing.scss';
import { Auth0ContextInterface, User } from '@auth0/auth0-react';

interface Props extends Auth0ContextInterface<User> {

}

export class Landing extends React.PureComponent<Props> {

    onSignInClick = () => {
        this.props.loginWithRedirect();
    }

    render() {
        return <div className="parent" id="landing-page">
            <div className="hero-background">

                <header className="header">
                    <nav className="navbar">
                        <img
                            className="logo"
                            src="static/images/logo-wide-light.svg"
                            alt="Route Tree Logo"
                        />
                        <div className="header-cta">
                            <a className="login clickable" onClick={this.onSignInClick}>Sign In</a>
                            <div>
                                <form
                                    action="https://routetree.us4.list-manage.com/subscribe/post"
                                    target="_blank"
                                    method="POST"
                                >
                                    <input
                                        type="hidden"
                                        name="u"
                                        value="f2e1ac7121e106979594fc8f9"
                                    />
                                    <input
                                        type="hidden"
                                        name="id"
                                        value="b7fa27ae92"
                                    />
                                    <input
                                        type="hidden"
                                        name="landing-ref"
                                        value="header"
                                    />
                                    <button className="get-started" type="submit">
                                        Get Started
                                    </button>
                                </form>
                            </div>
                        </div>
                    </nav>
                </header>

                <section className="hero wrapper">
                    <div className="hero-left">
                        <div className="notification">
                            <p className="message">
                                <span className="status">Updated</span>We just updated
                                the
                                <span style={{ color: "white" }}>Live Freight Tracking </span>
                                page
                            </p>
                        </div>
                        <div className="hero-text">
                            <h1>Automate your load planning.</h1>
                            <p>
                                Route Tree is a software tool that matches your
                                unplanned freight to the most efficient driver in
                                your fleet.
                            </p>
                        </div>
                        <div className="buttons">
                            <a href="#" className="cta cta-primary">Get Started</a>
                        </div>
                    </div>
                    <div className="hero-right">
                        <img
                            className="web-app"
                            src="static/images/route-tree-web-app.svg"
                            alt="Web App"
                        />
                    </div>
                </section>
            </div>

            <div className="section-one" id="learn-more">
                <div className="flex-container wrapper-x-narrow">

                    <section className="sidebar">
                        <img className="data" src="static/images/data.svg" alt="Algorithm" />
                    </section>


                    <section className="content">
                        <h2>Dynamic Algorithm</h2>
                        <p>
                            Route Tree uses a variety of data points within its
                            algorithm to effeciently match your list of freight to
                            the best possible driver in your fleet. The software is
                            able to plan several trips in advance allowing carriers
                            to maximize revenue, reduce dead-head time, and cut back
                            on expenses such as fuel.
                        </p>
                    </section>
                </div>
            </div>

            <div className="section-two">
                <div className="flex-container wrapper-x-narrow">

                    <section className="sidebar">
                        <img
                            className="web-app"
                            src="static/images/dashboard.svg"
                            alt="Web App"
                        />
                    </section>

                    <section className="content">
                        <h2>Real-Time Management</h2>
                        <p>
                            Route Tree's user-friendly web application allows your
                            company to easily keep track of its drivers and freight
                            in real-time. Additionally, you will have access to
                            in-depth reporting on your company's operations allowing
                            you to make smarter decisions with your business.
                        </p>
                    </section>
                </div>
            </div>

            <div className="section-three">
                <div className="flex-container wrapper-narrow">

                    <section className="features">
                        <div>
                            <i className="fad fa-route"></i>
                            <h2>Humanless Load Planning</h2>
                        </div>
                        <p>
                            Upload your fleet of drivers along with your freight and
                            sit back and watch our algorithm create the most
                            efficient plan for your drivers.
                        </p>
                    </section>

                    <section className="features">
                        <div>
                            <i className="fad fa-users"></i>
                            <h2>Driver Retention</h2>
                        </div>
                        <p>
                            Boost your driver happiness by giving them everything
                            they want. More money in their pocket, less sitting,
                            no-go states, and much more!
                        </p>
                    </section>

                    <section className="features">
                        <div>
                            <i className="fad fa-clipboard-check"></i>
                            <h2>Automatic Acceptance</h2>
                        </div>
                        <p>
                            Tell us which customers you work with and we will make
                            sure every load tendered to you that works for your
                            drivers gets accepted and assigned.
                        </p>
                    </section>
                </div>
            </div>

            <footer className="footer">
                <h6>Copyright © 2020 Route Tree, LLC</h6>
            </footer>
        </div>
    }
}