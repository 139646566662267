/// <reference path="../../../repository/drivers-repository.d.ts"/>
import { Button } from '@material-ui/core';
import { set } from 'lodash';
import * as React from 'react';
import { InputField } from '../../shared-components/input/InputField';
import './DriverProfile.scss';
import { getStates } from '../../../repository/state-repository';
import { getCommodities } from '../../../repository/commodity-repository';
import { getGenders } from '../../../repository/gender-repository';
import { SelectField } from '../../shared-components/input/SelectField';
import { updateDriver } from '../../../repository/drivers-repository';

interface Props {
    driver: IDriver;
    className?: string;
    onChange: (driver: IDriver) => void
}

interface State {
    isEditing: boolean;
    states: IState[];
    commodities: ICommodity[];
    genders: IGender[];
}

export class DriverProfile extends React.PureComponent<Props, State> {

    state: State = {
        isEditing: false,
        states: [],
        commodities: [],
        genders: []
    }

    original: IDriver | null = null;

    async componentDidMount() {
        const [states, commodities, genders] = await Promise.all([getStates(), getCommodities(), getGenders()]);
        this.setState({ states, commodities, genders });
    }

    onChangeProperty = (value: string | number | string[] | number[], property: string) => {
        const driver = { ...this.props.driver };
        set(driver, property, value)
        this.props.onChange(driver);
    }

    onEditClick = () => {

        this.original = JSON.parse(JSON.stringify(this.props.driver)) as IDriver;

        this.setState({
            isEditing: true
        });
    }

    onCancelClick = () => {
        if (this.original) {
            this.props.onChange(this.original);
        }

        this.setState({
            isEditing: false
        });
    }

    onSaveClick = async () => {
        const driver = await updateDriver(this.props.driver)
        this.setState({
            isEditing: false
        });

        if (driver) {
            this.props.onChange(driver);
        }
    }

    onFreightBlackListChange = (data: string | number | string[] | number[]) => {
        const driver = {...this.props.driver};

        if (Array.isArray(data)) {
            driver.driverCommodityBlacklist = data.map((w:any) => ({ commodityId: w, driverId: driver.driverId } as any))
            this.props.onChange(driver);
        }
    }

    onStateBlackListChange = (data: string | number | string[] | number[]) => {
        const driver = {...this.props.driver};

        if (Array.isArray(data)) {
            driver.driverStateBlacklist = data.map((w:any) => ({ stateId: w, driverId: driver.driverId } as any))
            this.props.onChange(driver);
        }
    }

    render() {
        return <div className={this.props.className} id="personal-information-container">
            <div className="section-header">
                <div>
                    <h2 className="section-title">Personal Information</h2>
                </div>
                <div>
                    {this.state.isEditing === false && <Button variant="contained" className="btn-white" onClick={this.onEditClick}>Edit <i className="fas fa-pencil"></i></Button>}
                    {this.state.isEditing === true && <React.Fragment>
                        <Button variant="text" color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={this.onSaveClick}>Save</Button>
                    </React.Fragment>}
                </div>
            </div>
            <div className="split-info">
                <div className="info-left">
                    <div className="double-field">
                        <div className="panel-row">
                            <InputField label="First Name" isEditing={this.state.isEditing} value={this.props.driver.firstName} onChange={e => this.onChangeProperty(e, "firstName")} />
                        </div>
                        <div className="panel-row last">
                            <InputField label="Last Name" isEditing={this.state.isEditing} value={this.props.driver.lastName} onChange={e => this.onChangeProperty(e, "lastName")} />
                        </div>
                    </div>
                    <div className="panel-row">
                        <InputField type="date" label="Date of Birth" isEditing={this.state.isEditing} value={this.props.driver.dateOfBirth ?? ""} onChange={e => this.onChangeProperty(e, "dateOfBirth")} />
                    </div>
                    <div className="panel-row">
                        <InputField label="Home Address" isEditing={this.state.isEditing} value={this.props.driver.hometownPlace?.formattedAddress} onChange={e => this.onChangeProperty(e, "hometownPlace.formattedAddress")} />
                    </div>
                    <div className="panel-row">
                        <InputField type="time" label="Hometime" isEditing={this.state.isEditing} value={this.props.driver.hometimeDesiredDateTime} onChange={e => this.onChangeProperty(e, "hometimeDesiredDateTime")} />
                    </div>
                    <div className="panel-row">
                        <SelectField
                            multiple={true}
                            label="Blacklist Freight"
                            isEditing={this.state.isEditing}
                            items={this.state.commodities.map(w => ({ value: w.commodityId, label: w.commodityName }))}
                            onChange={this.onFreightBlackListChange}
                            value={this.props.driver.driverCommodityBlacklist.map(w => w.commodityId)}
                        />
                    </div>
                    <div className="panel-row">
                        <SelectField
                            multiple={true}
                            label="Blacklist States"
                            isEditing={this.state.isEditing}
                            items={this.state.states.map(w => ({ value: w.stateId, label: w.stateAbbreviation }))}
                            onChange={this.onStateBlackListChange}
                            value={this.props.driver.driverStateBlacklist.map(w => w.stateId)}
                        />
                    </div>
                </div>

                <div className="info-right">
                    <div className="panel-row">
                        <p className="panel-key">Driver Manager</p>
                        <p className="panel-value">{this.props.driver.manager.firstName} {this.props.driver.manager.lastName}</p>
                    </div>
                    <div className="panel-row">
                        <p className="panel-key">Driver's License</p>
                        <p className="panel-value"></p>
                    </div>
                    <div className="panel-row">
                        <p className="panel-key">Exp. Date</p>
                        <p className="panel-value"></p>
                    </div>
                    <div className="panel-row">
                        <p className="panel-key">Account Number</p>
                        <p className="panel-value">{this.props.driver.driverId}</p>
                    </div>
                    <div className="panel-row">
                        <SelectField
                            label="Gender"
                            isEditing={this.state.isEditing}
                            items={this.state.genders.map(w => ({ value: w.genderId, label: w.genderName }))}
                            onChange={e => this.onChangeProperty(e, "genderId")}
                            value={this.props.driver.genderId ?? 0}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}