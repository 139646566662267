/// <reference path="../common-types.d.ts"/>
/// <reference path="./freight-repository.d.ts"/>
import { createAuthorizedHeaders } from "./base/base-repository";

export const getIsHealthy = async (): Promise<string | null> => {
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/health`, {
        headers,
        method: "POST"
    });

    if (response.ok === false) {
        return null;
    }

    return await response.text();
}