import * as React from 'react';

interface Props {
    freight?: IFreight | null;
}

export class BingMap extends React.PureComponent<Props> {

    async componentDidMount() {

        (window as any).bingMapLoaded = () => {
            const map = new Microsoft.Maps.Map(document.getElementById('bing-map')!, {
                credentials: "AihYrnnR39FyjsPmDHsNfZpVOZEnWog3JxdRvIvkvX42GQMAv6YPEQDlnUx0Mnfg"
            });

            // this is ok to recompute because we are only showing the route, don't care about time here
            if (this.props.freight) {

                Microsoft.Maps.loadModule('Microsoft.Maps.Directions', () => {
                    const directionsManager = new Microsoft.Maps.Directions.DirectionsManager(map);
                    directionsManager.setRequestOptions({
                        routeMode: "truck" as any
                    });
                    const wp1 = new Microsoft.Maps.Directions.Waypoint({
                        location: new Microsoft.Maps.Location(this.props.freight!.route!.startLatitude, this.props.freight!.route!.startLongitude)
                    });
                    const wp2 = new Microsoft.Maps.Directions.Waypoint({
                        location: new Microsoft.Maps.Location(this.props.freight!.pickupPlace.latitude, this.props.freight!.pickupPlace.longitude),
                        isViaPoint: true,
                    });
                    const wp3 = new Microsoft.Maps.Directions.Waypoint({
                        location: new Microsoft.Maps.Location(this.props.freight!.dropoffPlace.latitude, this.props.freight!.dropoffPlace.longitude)
                    });
                    directionsManager.addWaypoint(wp1);
                    directionsManager.addWaypoint(wp2);
                    directionsManager.addWaypoint(wp3);
                    directionsManager.calculateDirections();
                });
            }
        }

        const script = document.createElement('script') as HTMLScriptElement;

        script.src = "https://www.bing.com/api/maps/mapcontrol?key=AihYrnnR39FyjsPmDHsNfZpVOZEnWog3JxdRvIvkvX42GQMAv6YPEQDlnUx0Mnfg&callback=bingMapLoaded";

        document.body.appendChild(script);
    }

    render() {
        return <div style={{ width: "100%", height: "400px" }} id="bing-map"></div>
    }
}