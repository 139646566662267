import { TextField } from '@material-ui/core';
import * as React from 'react';

interface Props {
    label: string;
    value: string | number;
    onChange: (value: string) => void;
    isEditing: boolean;
    type?: string;
}

export const InputField: React.FunctionComponent<Props> = (props) => {

    if (props.isEditing === true) {
        return <TextField type={props.type} label={props.label} variant="filled" value={props.value} onChange={e => props.onChange(e.target.value)} />
    }

    return <React.Fragment>
        <p className="panel-key">{props.label}</p>
        <div className="panel-value">{props.value}</div>
    </React.Fragment>
}