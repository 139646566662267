/// <reference path="../common-types.d.ts"/>
/// <reference path="./freight-repository.d.ts"/>
import { createAuthorizedHeaders } from "./base/base-repository";

export const updateSchedule = async (): Promise<IFreight[]> => {
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/scheduling`, {
        headers,
        method: "PUT"
    });

    if (response.ok === false) {
        return [];
    }

    return await response.json();
}