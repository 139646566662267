import * as moment from "moment";

export const usingDate = (date: string | Date) => {
    const response = {
        value: date,
        format: () => {
            return moment(date).format("M/D/YYYY h:mm A")
        },
        toUtc: () => {
            const formattedDate = response.format();
            const utc = new Date(`${formattedDate} UTC`);
            const result = usingDate(utc);

            delete (result as any).toUtc;

            return result as { format: () => string, value: string | Date };
        }
    };

    return response
}
