/// <reference path="../common-types.d.ts"/>
/// <reference path="./commodity-repository.d.ts"/>
import { createAuthorizedHeaders } from "./base/base-repository";

export const getCommodities = async (): Promise<ICommodity[]> => {
    const headers = await createAuthorizedHeaders();
    const response = await fetch(`${appSettings.apiEndpoint}/commodities`, {
        headers,
        method: "GET"
    });

    if (response.ok === false) {
        return [];
    }

    return await response.json();
}