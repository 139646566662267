import { orderBy, sum } from 'lodash';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { currencyFormatter, formatEta, getRouteType } from '../../../../../common/helpers';
import { getFreightById } from '../../../../../repository/freight-repository';
import { usingDate } from '../../../../../common/RouteTreeDate';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@material-ui/core';
import './FreightDetail.scss';
import { BingMap } from '../../../../shared-components/bing/maps/BingMap';
import { DriverProfile } from '../../../../shared-components/driver/DriverProfile';

interface Props {

}

interface State {
    freight: IFreight | null;
}

export class FreightDetail extends React.PureComponent<Props> {

    state: State = {
        freight: null
    }

    async componentDidMount() {
        const props: RouteComponentProps<{ id: string; }, any, any> = this.props as any;
        const match = props.match;
        const freight = await getFreightById(match.params.id);

        this.setState({
            freight
        })
    }

    formatEta = (routeLeg: IRouteLeg) => {


        return formatEta(routeLeg.estimatedTimeToComplete);
    }

    onDriverChange = (assignedDriver: IDriver) => {
        const freight = { ...this.state.freight, assignedDriver };
        this.setState({ freight })
    }

    render() {

        return <div className="freight-detail-page">
            <div className="section-container">

                <div className="section-header driver">
                    <div className="freight-header">
                        <div>
                            <i className="fas fa-truck-container row-icon"></i>&nbsp;<span>Freight</span>
                        </div>
                        <h2 className="freight-name">{this.state.freight?.customer.customerName}</h2>
                    </div>
                </div>

                <div className="driver-secondary">
                    <div className="email brgrey">
                        <p className="title">Driver</p>
                        <p className="driver-email">{this.state.freight?.assignedDriver?.email}</p>
                    </div>
                    <div className="phone brgrey">
                        <p className="title">Phone</p>
                        <p className="driver-phone">(858) 353-3196</p>
                    </div>
                    <div className="truck brgrey">
                        <p className="title">Truck #</p>
                        <p className="driver-truck">2983652</p>
                    </div>
                    <div className="trailer brgrey">
                        <p className="title">Trailer #</p>
                        <p className="driver-truck">2983652</p>
                    </div>
                    <div className="revenue last">
                        <p className="title">Revenue</p>
                        <p className="driver-truck">{currencyFormatter.format(this.state.freight?.revenue ?? 0)}</p>
                    </div>
                </div>
            </div>

            <div className="section-container map">
                <div className="section-header">
                    <h2 className="section-title">Map</h2>
                </div>
                <BingMap
                    freight={this.state.freight}
                />
            </div>

            <div className="section-container">
                <div className="section-header">
                    <h2 className="section-title">Route Details</h2>
                </div>
                {this.state.freight?.route?.routeLegs && <Accordion>
                    <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down"></i>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{this.state.freight.pickupPlace.formattedAddress} -&gt; {this.state.freight.dropoffPlace.formattedAddress}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Distance</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Completion Time</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orderBy(this.state.freight.route.routeLegs, w => w.order).map((w, i) => <tr>
                                        <td>{w.name}</td>
                                        <td>{w.distance.toFixed(2)} mi</td>
                                        <td>{usingDate(w.startDateTime).toUtc().format()}</td>
                                        <td>{usingDate(w.endDateTime).toUtc().format()}</td>
                                        <td>{this.formatEta(w)}</td>
                                        <td>{getRouteType(w.routeLegTypeId)}</td>
                                    </tr>)
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Totals:</td>
                                    <td>{sum(this.state.freight.route.routeLegs.map(w => w.distance)).toFixed(2)} mi</td>
                                    <td>{formatEta(sum(this.state.freight.route.routeLegs.map(w => w.estimatedTimeToComplete)))}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </AccordionDetails>
                </Accordion>}
            </div>

            {this.state.freight?.assignedDriver && <DriverProfile
                className="section-container"
                driver={this.state.freight.assignedDriver}
                onChange={this.onDriverChange}
            />}
        </div>
    }
}